import { Box, Breadcrumb, BreadcrumbItem } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { SitemapPageQuery } from '../../../graphql-types';
import Layout from '../../components/Layout';
import { WLContainer, WLH1V2, WLLink, WLText } from '../../design-library';

interface SitemapPageProps extends PageProps<SitemapPageQuery> {}
interface SitemapEntryProps {
  pages: { path: string; label: string }[];
  path: string;
}
interface BreadcrumbEntryProps {
  pages: { path: string; label: string }[];
  path: string;
  isLast: boolean;
}

const useSitemapData = (
  data: SitemapPageQuery,
): { path: string; label: string }[] => {
  return React.useMemo(() => {
    const allPages = data.allSitePage.nodes;
    const contentfulPages = data.allContentfulPage.nodes;
    const contentfulPersons = data.allContentfulPerson.nodes;
    return allPages.map((page) => {
      const contentfulData = contentfulPages.find(
        (cpage) => cpage.slug === page.path,
      );
      if (contentfulData) {
        return {
          path: page.path,
          label: contentfulData.breadcrumbTitle || '',
        };
      }
      const contentfulPerson = contentfulPersons.find(
        (cperson) => cperson.slug === page.path,
      );
      if (contentfulPerson) {
        return {
          path: page.path,
          label: contentfulPerson.fullName || '',
        };
      }
      return {
        path: page.path,
        label: '',
      };
    });
  }, [data]);
};

const formatLabel = (segment: string): string => {
  if (segment === '') {
    return 'Home';
  }
  if (segment === 'lifecareerbalance') {
    return 'Life-Career Balance';
  }
  if (segment === 'applynow') {
    return 'Apply Now';
  }
  return segment
    .split('-')
    .map((word) => `${word[0].toUpperCase()}${word.substring(1)}`)
    .join(' ');
};

const BreadcrumbEntry: React.FC<BreadcrumbEntryProps> = ({
  pages,
  path,
  isLast,
}) => {
  let label;
  const page = pages.find((page) => page.path === path);
  if (page && page.label) {
    label = page.label;
  } else {
    const segments = path.substring(0, path.length - 1).split('/');
    const segment = segments[segments.length - 1];
    label = formatLabel(segment);
  }

  if (isLast) {
    return (
      <WLLink to={path} fontWeight={700} variant="plain">
        {label}
      </WLLink>
    );
  }
  return <WLText>{label}</WLText>;
};

const SitemapEntry: React.FC<SitemapEntryProps> = ({ pages, path }) => {
  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }
  const segments = path.split('/');

  return (
    <Breadcrumb
      sx={{
        ol: {
          display: 'block',
          pb: {
            base: '18px',
            lg: '12px',
          },
        },
      }}
    >
      {segments.map((segment, index) => (
        <BreadcrumbItem
          key={index}
          color={index === segments.length - 1 ? 'blue.800' : 'gray.600'}
        >
          <BreadcrumbEntry
            pages={pages}
            path={
              index === 0 ? '/' : `${segments.slice(0, index + 1).join('/')}/`
            }
            isLast={index === segments.length - 1}
          />
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

const SitemapPage: React.FC<SitemapPageProps> = ({ data, location }) => {
  const pages = useSitemapData(data);

  return (
    <Layout seo={{ title: 'Sitemap' }} currentPath={location.pathname}>
      <WLContainer
        width="EXTRA_WIDE"
        align="start"
        paddingX={{ base: 4, lg: 8 }}
        paddingTop={{ base: 2, lg: 2 }}
        paddingBottom={{ base: 3, lg: 3 }}
      ></WLContainer>
      <WLContainer width="STANDARD" paddingX={2}>
        <WLH1V2>Sitemap</WLH1V2>
        <Box>
          {pages.map((page) => (
            <SitemapEntry key={page.path} pages={pages} path={page.path} />
          ))}
        </Box>
      </WLContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query SitemapPage {
    allSitePage(
      sort: { fields: path, order: ASC }
      filter: {
        path: { glob: "!/(activities|brochure|chapter|reps|rsm|404*)/**" }
      }
    ) {
      nodes {
        path
      }
    }
    allContentfulPage {
      nodes {
        slug
        breadcrumbTitle
      }
    }
    allContentfulPerson(filter: { isBoardMember: { eq: true } }) {
      nodes {
        slug
        fullName
      }
    }
  }
`;

export default SitemapPage;
